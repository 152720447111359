<template>
	<CustomModal
		:title="$t('ChangePwd.Title')"
		:submit-text="$t('ChangePwd.Submit')"
		:cancel-text="$t('ChangePwd.Cancel')"
		size="lg"
		@submit-modal="changePwd"
		@cancel-modal="cancel"
	>
		<template #content>
			<div class="change-pwd-form custom-form">
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(changePwd)">
						<!-- New Password -->
						<div class="form-group">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="admin_new_password"
								:rules="`requiredChar|max:${maxLength}|min:${minLength}`"
								mode="lazy"
							>
								<div :class="[classes, 'row']">
									<label class="col-4 col-auto col-form-label" for="new-password">{{
										$t("ChangePwd.NewPassword")
									}}</label>
									<div class="col-8 col-md-6">
										<div class="position-relative">
											<input
												id="new-password"
												v-model="newPassword"
												class="form-control"
												autocomplete="new-password"
												:type="showNewPwd ? 'password' : 'text'"
												:maxlength="maxLength"
											/>
											<div v-if="newPassword" @click="showNewPassword">
												<span v-if="!showNewPwd" class="input-group-text icon-input">
													<font-awesome-icon
														:title="$t('ChangePwd.hidePwd')"
														:icon="['fas', 'eye-slash']"
													/>
												</span>

												<span v-else class="input-group-text icon-input">
													<font-awesome-icon
														:title="$t('ChangePwd.showPwd')"
														:icon="['fas', 'eye']"
													/>
												</span>
											</div>
										</div>
										<div class="error-mess">
											{{ errors[0] }}
										</div>
									</div>
								</div>
							</ValidationProvider>
						</div>
					</form>
				</ValidationObserver>
			</div>
		</template>
	</CustomModal>
</template>

<script>
export default {
	name: "ChangeAdminPassword",

	props: {},

	data() {
		return {
			showNewPwd: true,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			minLength: process.env.VUE_APP_INPUT_MIN_LENGTH,

			newPassword: null,
		}
	},

	computed: {},

	watch: {},

	methods: {
		changePwd: async function () {
			const valid = await this.$refs.form.validate()
			if (!valid) return

			this.$emit("submit-form", this.newPassword)
		},

		cancel: function () {
			this.$emit("hide")
		},

		showNewPassword() {
			this.showNewPwd = !this.showNewPwd
		},
	},
}
</script>
<style lang="scss" scoped>
.change-pwd-form {
	margin-top: 20px;

	#new-password {
		padding-right: 42px;
	}
}
</style>
